import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import {
  MembershipStatus,
  Product,
  findMainMemberProductPremium,
  isProductPremiumFree,
} from "../../../../services/core-api-adapter";
import MembershipStatusIndicator from "../../../MembershipStatusIndicator";
import { Trans, useTranslation } from "react-i18next";
import { shadows } from "../../../../theme";
import { useState } from "react";
import SlideUpDialog from "../../../SlideUpDialog";
import MarketplaceProductChoosePlanForm from "../../../MarketplaceProductChoosePlanForm";
import IconLoader from "../../../IconLoader";
import MarketplaceProductChoosePlanSelfServiceForm from "../../../MarketplaceProductChoosePlanSelfServiceForm";
import { useGlobalStore } from "../../../../store";
import { convertPxToRem } from "../../../../utils";
import { trackEvent } from "../../../../services/analytics-adapter";

interface MarketplaceProductComparisonCardProps {
  product: Product;
  onClose: Function;
  membershipIdentifier?: string;
  isActivePlan?: boolean;
  isDetailedView?: boolean;
}

const productComparisonCardStyling = {
  backgroundColor: "secondary.main",
  borderRadius: 2,
  height: "100%",
  boxShadow: shadows.light,
  overflow: "hidden",
  position: "relative",
};

export default function MarketplaceProductComparisonCard({
  product,
  onClose,
  isActivePlan = false,
  isDetailedView = false,
  membershipIdentifier = "",
}: MarketplaceProductComparisonCardProps) {
  const { t } = useTranslation();
  const { state } = useGlobalStore();
  const mainMemberProductPremium = findMainMemberProductPremium(product);
  const [isChooseProductModalOpen, setIsChooseProductModalOpen] =
    useState(false);

  function onChoosePlanButtonClick() {
    trackEvent({
      event: "action.marketplaceCheckoutStarted",
      productId: product?.id,
      productName: product?.name,
      "checkoutStarted.source": isDetailedView
        ? "compareDetailView"
        : "compareSummaryView",
    });
    return setIsChooseProductModalOpen(true);
  }

  return (
    <Box
      pb={2}
      sx={{
        ...productComparisonCardStyling,
      }}
    >
      <Box sx={{ p: 2, minHeight: "50%" }} mb={2}>
        <Stack alignItems="flex-end" mb={1}>
          <IconButton
            sx={{ p: 0 }}
            aria-label={t("common.removeButton")}
            onClick={() => onClose()}
          >
            <IconLoader icon="CrossIcon" sx={{ p: 0.2, mr: 0.5 }} />
          </IconButton>
        </Stack>
        <Typography variant="h4">{product.name}</Typography>
      </Box>

      <Stack spacing={2} p={2} height="100%" bgcolor="background.paper">
        <Typography variant="h4" color="neutral.500">
          {isProductPremiumFree(mainMemberProductPremium) ? (
            <Typography variant="h3" component="span" fontWeight="600">
              {t("MarketplaceProductCard.noPriceLabelText")}
            </Typography>
          ) : (
            <Trans
              i18nKey="common.pricePerMonth"
              values={{
                price: mainMemberProductPremium?.premiumFormatted,
                interval: t(
                  `PaymentInterval.${mainMemberProductPremium?.interval as any}.label` as any
                ),
              }}
            >
              <Typography
                variant="h3"
                component="span"
                fontWeight="600"
                fontSize={convertPxToRem(16)}
              ></Typography>
            </Trans>
          )}
        </Typography>

        <Stack alignItems="center" justifyContent="center">
          {isActivePlan ? (
            <Box>
              <MembershipStatusIndicator status={MembershipStatus.ACTIVE} />
            </Box>
          ) : (
            <Button
              size="small"
              fullWidth
              onClick={onChoosePlanButtonClick}
              aria-label={t("common.choosePlanButton")}
            >
              <IconLoader icon="TrolleyIcon" sx={{ mr: 1 }} />
              {isDetailedView &&
                t(
                  "MarketplaceProductComparison.productComparisonCard.chooseButtonLabel"
                )}
            </Button>
          )}
        </Stack>
      </Stack>
      <SlideUpDialog
        isOpen={isChooseProductModalOpen}
        onClose={() => setIsChooseProductModalOpen(false)}
        label={t("MembershipManagementChangePlanForm.title")}
      >
        {state.currentUser.isRetailMember === true ? (
          <MarketplaceProductChoosePlanSelfServiceForm
            product={product}
            onClose={() => setIsChooseProductModalOpen(false)}
          />
        ) : (
          <MarketplaceProductChoosePlanForm
            membershipIdentifier={membershipIdentifier}
            product={product}
            onCancel={() => setIsChooseProductModalOpen(false)}
            onClose={() => setIsChooseProductModalOpen(false)}
          />
        )}
      </SlideUpDialog>
    </Box>
  );
}
