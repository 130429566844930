import { Button, Divider, Stack, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import {
  Member,
  MembershipStatus,
  Product,
  findMainMemberInMembership,
  findMainMemberProductPremium,
  isProductPremiumFree,
} from "../../services/core-api-adapter";
import { convertPxToRem } from "../../utils";
import MarketplaceProductAdditionalBenefitsPanel from "./components/MarketplaceProductAdditionalBenefitsPanel";
import MarketplaceProductBenefitsList from "./components/MarketplaceProductBenefitsList";
import MaxWidthContainer from "../MaxWidthContainer";
import { shadows } from "../../theme";
import SlideUpDialog from "../SlideUpDialog";
import MarketplaceProductChoosePlanForm from "../MarketplaceProductChoosePlanForm";
import useGetMemberships from "../../hooks/useGetMemberships";
import MembershipStatusIndicator from "../MembershipStatusIndicator";
import IconLoader from "../IconLoader";
import { useGlobalStore } from "../../store";
import ProductPartnerList from "../ProductPartnerList";
import MarketplaceProductChoosePlanSelfServiceForm from "../MarketplaceProductChoosePlanSelfServiceForm";
import { trackEvent } from "../../services/analytics-adapter";

interface MarketplaceProductProps {
  product: Product;
}

export default function MarketplaceProduct({
  product,
}: MarketplaceProductProps) {
  const { t } = useTranslation();
  const { state } = useGlobalStore();
  const mainMemberProductPremium = findMainMemberProductPremium(product);
  const { firstMembership, memberships, isMembershipsLoading } =
    useGetMemberships();

  const [member, setMember] = useState<Member | null>(null);
  const [shouldDisplayChoosePlanButton, setShouldDisplayChoosePlanButton] =
    useState<boolean>(false);
  const [isChooseProductModalOpen, setIsChooseProductModalOpen] =
    useState(false);

  function onChoosePlanButtonClick() {
    trackEvent({
      event: "action.marketplaceCheckoutStarted",
      productId: product?.id,
      productName: product?.name,
      "checkoutStarted.source": "moreInfoView",
    });
    return setIsChooseProductModalOpen(true);
  }

  useEffect(() => {
    setMember(
      findMainMemberInMembership(firstMembership, state.currentUser.memberId)
    );
    const foundActiveMembership = memberships.filter((membership) => {
      return membership.productDetails.code === product.code;
    });

    if (!isMembershipsLoading) {
      setShouldDisplayChoosePlanButton(
        foundActiveMembership.length === 0 ? true : false
      );
    }
  }, [memberships]);

  return (
    <Stack
      pb={convertPxToRem(40)}
      spacing={2}
      aria-label={t("MarketplaceProductList.title")}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography>
            {t(
              `MarketplaceProductDetail.MemberType.${mainMemberProductPremium?.memberType}.title` as any
            )}
          </Typography>
          <Typography
            variant="h3"
            fontWeight={600}
            color={(theme) => theme.palette.neutral[500]}
          >
            {isProductPremiumFree(mainMemberProductPremium) ? (
              <Typography component="span" variant="h1" fontWeight="600">
                {t("MarketplaceProductCard.noPriceLabelText")}
              </Typography>
            ) : (
              <Trans
                i18nKey="common.pricePerMonth"
                values={{
                  price: mainMemberProductPremium?.premiumFormatted,
                  interval: t(
                    `PaymentInterval.${mainMemberProductPremium?.interval as any}.label` as any
                  ),
                }}
              >
                <Typography
                  variant="h1"
                  component="span"
                  fontWeight="600"
                ></Typography>
              </Trans>
            )}
          </Typography>
        </Box>

        {shouldDisplayChoosePlanButton ? (
          <Stack>
            <Button onClick={onChoosePlanButtonClick} size="medium">
              <IconLoader icon="TrolleyIcon" sx={{ mr: 1 }} />
              {t("MarketplaceProductDetail.ChoosePlan.button.label")}
            </Button>
          </Stack>
        ) : (
          <>
            {!isMembershipsLoading && (
              <MembershipStatusIndicator status={MembershipStatus.ACTIVE} />
            )}
          </>
        )}
      </Stack>
      <Divider />

      <Typography variant="h3">
        {t("MarketplaceProductDetail.benefitsIncluded.title")}
      </Typography>

      <MarketplaceProductBenefitsList
        productBenefits={product?.benefitCategory}
      />

      <Typography variant="h3">
        {t("MarketplaceProductDetail.benefitsAdditions.title")}
      </Typography>

      <MarketplaceProductAdditionalBenefitsPanel product={product} />
      <Divider />

      <Stack
        sx={{
          background: (theme) => theme.palette.grey[50],
          pt: 1.5,
          pb: 2,
          px: 2,
        }}
        direction={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        spacing={2}
      >
        <ProductPartnerList product={product} />
      </Stack>

      <Box
        sx={{
          bottom: 0,
          left: 0,
          position: "fixed",
          width: "100%",
        }}
      >
        {shouldDisplayChoosePlanButton && (
          <MaxWidthContainer>
            <Stack
              component="article"
              aria-label={t("MarketplaceProductDetail.ChoosePlan.panelLabel")}
              spacing={1}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              bgcolor="primary.main"
              color="primary.contrastText"
              sx={{
                borderRadius: 3,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                boxShadow: shadows.medium,
                p: 2,
              }}
            >
              <Box>
                <Typography color="inherit" variant="h3">
                  {!mainMemberProductPremium ? (
                    <Typography component="span" variant="h1" fontWeight="600">
                      {t("MarketplaceProductCard.noPriceLabelText")}
                    </Typography>
                  ) : (
                    <Trans
                      i18nKey="common.pricePerMonth"
                      values={{
                        price: mainMemberProductPremium?.premiumFormatted,
                        interval: t(
                          `PaymentInterval.${mainMemberProductPremium?.interval as any}.label` as any
                        ),
                      }}
                    >
                      <Typography
                        component="span"
                        variant="h1"
                        color="inherit"
                      ></Typography>
                    </Trans>
                  )}
                </Typography>
              </Box>
              <Button
                color="info"
                onClick={onChoosePlanButtonClick}
                size="medium"
              >
                <IconLoader icon="TrolleyIcon" sx={{ mr: 1 }} />
                {t("MarketplaceProductDetail.ChoosePlan.button.label")}
              </Button>
            </Stack>
          </MaxWidthContainer>
        )}
      </Box>

      <SlideUpDialog
        isOpen={isChooseProductModalOpen}
        onClose={() => setIsChooseProductModalOpen(false)}
        label={t("MembershipManagementChangePlanForm.title")}
      >
        {state.currentUser.isRetailMember === true ? (
          <MarketplaceProductChoosePlanSelfServiceForm
            product={product}
            onClose={() => setIsChooseProductModalOpen(false)}
          />
        ) : (
          <MarketplaceProductChoosePlanForm
            membershipIdentifier={member?.membershipDetails?.id}
            product={product}
            onCancel={() => setIsChooseProductModalOpen(false)}
            onClose={() => setIsChooseProductModalOpen(false)}
          />
        )}
      </SlideUpDialog>
    </Stack>
  );
}
