import { useTranslation } from "react-i18next";
import { Box, Button, Stack, Typography } from "@mui/material";
import ProcessIndicatorIcon from "../ProcessIndicatorIcon";
import { trackEvent } from "../../services/analytics-adapter";
import { getConfigurationVariable } from "../../services/configuration-adapter";

interface MarketplaceProductChoosePlanSelfServiceFormFailureProps {
  onTryAgain: () => void;
}

export default function MarketplaceProductChoosePlanSelfServiceFormFailure({
  onTryAgain,
}: MarketplaceProductChoosePlanSelfServiceFormFailureProps) {
  const { t } = useTranslation();

  function onReportIssueButtonClick() {
    trackEvent({
      event: "action.supportRequested",
      source: "Marketplace product choose plan self-service failure",
    });
  }

  function onTryAgainButtonClick() {
    onTryAgain();
  }

  return (
    <>
      <Stack component="article" spacing={2}>
        <Box textAlign="center" sx={{ opacity: 0.5 }}>
          <ProcessIndicatorIcon type="error" height={64} width={64} />
        </Box>

        <Typography textAlign="center" variant="h3" fontWeight="600">
          {t(`MarketplaceProductChoosePlanSelfServiceFormFailure.title`)}
        </Typography>

        {(
          t<any, any, string[]>(
            `MarketplaceProductChoosePlanSelfServiceFormFailure.body`,
            {
              returnObjects: true,
            }
          ) as []
        ).map((text: string) => (
          <Typography key={text} textAlign="center">
            {text}
          </Typography>
        ))}
      </Stack>

      <Stack component="footer" spacing={1} direction="row">
        <Button
          component="a"
          href={getConfigurationVariable("VITE_APP_UNU_HEALTH_SUPPORT_URI")}
          target="_blank"
          size="small"
          variant="outlined"
          fullWidth
          onClick={onReportIssueButtonClick}
        >
          {t("common.reportIssue")}
        </Button>
        <Button size="small" fullWidth onClick={onTryAgainButtonClick}>
          {t("common.tryAgain")}
        </Button>
      </Stack>
    </>
  );
}
