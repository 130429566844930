import { ProductBenefitCategory } from "../../../../services/core-api-adapter";
import { Stack, Theme } from "@mui/material";
import WatermarkIcon from "../../../WatermarkIcon";
import ProductBenefitAccordion from "../../../ProductBenefitAccordion";
import theme from "../../../../theme";

interface MarketplaceProductBenefitsListProps {
  productBenefits: ProductBenefitCategory[] | undefined;
}

export default function MarketplaceProductBenefitsList({
  productBenefits,
}: MarketplaceProductBenefitsListProps) {
  return (
    <>
      {productBenefits?.map((benefitCategory) => {
        return (
          <Stack key={benefitCategory.name} spacing={2}>
            <Stack spacing={1}>
              {benefitCategory.benefits.map((benefit) => {
                return (
                  <ProductBenefitAccordion
                    key={benefit.name}
                    icon={
                      <WatermarkIcon
                        extra={{
                          alignSelf: "center",
                          fontSize: (theme: Theme) => theme.spacing(4),
                        }}
                        iconSize="medium"
                        color={theme.palette.primary[300]}
                        foreground="primary"
                        Icon={"CheckmarkIcon"}
                      />
                    }
                    benefit={benefit}
                  />
                );
              })}
            </Stack>
          </Stack>
        );
      })}
    </>
  );
}
