import { FormEvent, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Box, Button, Stack, Typography } from "@mui/material";
import {
  MembershipChangeRequestIntent,
  Product,
  submitMembershipChangeRequest,
} from "../../services/core-api-adapter";
import LoadingSpinner from "../LoadingSpinner";
import MembershipManagementChangeRequestFormResponse, {
  MembershipManagementChangeRequestResponseTypes,
} from "../MembershipManagementChangeRequestFormResponse";
import IconLoader from "../IconLoader";

interface MarketplaceProductChoosePlanFormProps {
  product: Product;
  membershipIdentifier?: string;
  onCancel?: () => void;
  onClose?: () => void;
}

enum FormState {
  COLLECTING = "COLLECTING_FORM_DATA",
  SUBMITTING = "SUBMITTING_FORM_DATA",
  SUCCEEDED = "SUBMISSION_SUCCEEDED",
  FAILED = "SUBMISSION_FAILED",
}

export default function MarketplaceProductChoosePlanForm({
  membershipIdentifier = "",
  product,
  onCancel,
  onClose,
}: MarketplaceProductChoosePlanFormProps) {
  const { t } = useTranslation();

  const [state, setState] = useState(FormState.COLLECTING);

  function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const controller = new AbortController();

    setState(FormState.SUBMITTING);

    submitMembershipChangeRequest(
      {
        intent: MembershipChangeRequestIntent.CHANGE_PLAN,
        membershipIdentifier: membershipIdentifier,
      },
      {
        signal: controller.signal,
      }
    )
      .then(() => {
        setState(FormState.SUCCEEDED);
      })
      .catch(() => {
        if (controller.signal.aborted === false) {
          setState(FormState.FAILED);
        }
      });
  }

  return (
    <>
      {state === FormState.COLLECTING && (
        <form onSubmit={onSubmit}>
          <Stack component="article" spacing={2}>
            <Box textAlign="center">
              <IconLoader
                icon="TrolleyWithPlusIcon"
                color="primary"
                sx={{
                  fontSize: (theme) => theme.spacing(10),
                }}
              />
            </Box>

            <Typography textAlign="center" variant="h3" fontWeight="600">
              {t("common.chooseThisPlan")}
            </Typography>

            {(
              t<any, any, string[]>("MarketplaceProductChoosePlanForm.body", {
                productName: product.name,
                returnObjects: true,
              }) as []
            ).map((text: string) => (
              <Typography key={text} textAlign="center">
                <Trans i18nKey={text as any} />
              </Typography>
            ))}

            <Stack component="footer" spacing={1}>
              <Button type="submit" size="small" fullWidth>
                {t("common.continueButton")}
              </Button>

              {onCancel && (
                <Button
                  size="small"
                  variant="text"
                  fullWidth
                  onClick={onCancel}
                >
                  {t("common.cancelButton")}
                </Button>
              )}
            </Stack>
          </Stack>
        </form>
      )}

      {state === FormState.SUBMITTING && (
        <Box textAlign="center">
          <LoadingSpinner />
        </Box>
      )}

      {state === FormState.SUCCEEDED && (
        <MembershipManagementChangeRequestFormResponse
          onClose={onClose}
          responseType={
            MembershipManagementChangeRequestResponseTypes["SUCCESS"]
          }
        />
      )}

      {state === FormState.FAILED && (
        <MembershipManagementChangeRequestFormResponse
          onClose={onClose}
          responseType={
            MembershipManagementChangeRequestResponseTypes["FAILURE"]
          }
        />
      )}
    </>
  );
}
