import { Box, Stack, Theme, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import FullscreenBackground from "../../components/FullscreenBackground";
import MarketplaceProduct from "../../components/MarketplaceProduct";
import useGetProduct from "../../hooks/useGetProduct";
import LoadingSpinner from "../../components/LoadingSpinner";
import { borderRadiusContainerConfig } from "../../theme";

interface MarketplaceProductDetailProps {
  productID: string | undefined;
}

export function MarketplaceProductDetail({
  productID,
}: MarketplaceProductDetailProps) {
  const { t } = useTranslation();

  const { product, productFetchError, isProductLoading } = useGetProduct({
    productID: productID || "",
  });

  return (
    <>
      <Stack
        bgcolor="secondary.main"
        sx={{
          pb: (theme: Theme) =>
            `${
              theme.shape.borderRadius *
              borderRadiusContainerConfig.containerBorderRadiusMultiplier
            }px`,
        }}
      >
        <Stack spacing={1} sx={{ p: 2, mb: 2 }}>
          <Typography textAlign="center" variant="h1">
            {product?.name}
          </Typography>

          {product?.productProvider?.name && (
            <Typography textAlign="center" variant="body1">
              {t("MarketplaceProductCard.productProvider", {
                productProvider: product?.productProvider?.name,
              })}
            </Typography>
          )}
        </Stack>
      </Stack>

      <Box
        flexGrow={1}
        p={2}
        bgcolor="neutral.50"
        sx={{
          borderRadius:
            borderRadiusContainerConfig.containerBorderRadiusMultiplier,
          position: "relative",
          top: (theme: Theme) =>
            `-${
              theme.shape.borderRadius *
              borderRadiusContainerConfig.containerBorderRadiusMultiplier
            }px`,
        }}
      >
        {isProductLoading && (
          <Stack alignItems="center">
            <LoadingSpinner />
          </Stack>
        )}

        {!isProductLoading && productFetchError && (
          <Typography textAlign="center">
            {t("common.somethingWentWrong")}
          </Typography>
        )}

        {!isProductLoading && !productFetchError && product === null && (
          <Typography textAlign="center">
            {t("MarketplaceProductDetail.empty")}
          </Typography>
        )}

        {!isProductLoading && !productFetchError && product && (
          <MarketplaceProduct product={product} />
        )}
      </Box>
      <FullscreenBackground color="secondary.main" />
    </>
  );
}
