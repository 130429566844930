import { Grid, IconButton, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { convertPxToRem } from "../../../../utils";
import IconLoader from "../../../IconLoader";

interface ProductComparisonCardPlaceHolderProps {
  count: number;
  onAddNewPlanButtonClick: Function;
  isDetailedView?: boolean;
}

export default function ProductComparisonCardPlaceHolder({
  count,
  onAddNewPlanButtonClick,
  isDetailedView = false,
}: ProductComparisonCardPlaceHolderProps) {
  const { t } = useTranslation();

  return (
    <>
      {[...Array(count)].map((item, index) => {
        const ariaLabel = t(
          "MarketplaceProductComparison.productComparisonCard.placeholder.ariaLabel"
        );
        return (
          <Grid
            key={ariaLabel + String(index)}
            item
            xs={isDetailedView ? 6 : 4}
            role="listitem"
          >
            <Stack
              aria-label={ariaLabel}
              component="article"
              border={`2px dashed`}
              borderColor="primary.main"
              borderRadius={2}
              bgcolor="background.paper"
              height="100%"
              minHeight={convertPxToRem(200)}
              spacing={2}
              p={2}
              alignItems="center"
              justifyContent="center"
              onClick={() => onAddNewPlanButtonClick()}
              sx={{
                cursor: "pointer",
              }}
            >
              <IconButton
                color="primary"
                sx={{
                  borderRadius: "100%",
                  borderWidth: 2,
                  borderStyle: "solid",
                  borderColor: "primary.main",
                }}
                aria-label={t(
                  "MarketplaceProductComparison.productComparisonCard.addNewPlanButton"
                )}
              >
                <IconLoader icon="PlusIcon" />
              </IconButton>
              <Typography variant="body1" textAlign="center" color="primary">
                {t(
                  "MarketplaceProductComparison.productComparisonCard.placeholder.tile"
                )}
              </Typography>
            </Stack>
          </Grid>
        );
      })}
    </>
  );
}
