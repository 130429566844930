import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import IconLoader from "../../../IconLoader";

interface ProductBenefitSupportIndicatorProps {
  isSupported?: boolean;
  productBenefitName?: string;
  productName?: string;
}

export default function ProductBenefitSupportIndicator({
  isSupported,
  productBenefitName = "",
  productName = "",
}: ProductBenefitSupportIndicatorProps) {
  const { t } = useTranslation();

  if (isSupported !== undefined) {
    return (
      <Box
        role="listitem"
        aria-label={productName + productBenefitName}
        sx={{
          background: (theme) => {
            if (isSupported === true) {
              return theme.palette.primary[300];
            }
            return theme.palette.neutral[100];
          },
          border: (theme) => `1px solid ${theme.palette.neutral[300]}`,
          borderRadius: 2,
          minHeight: (theme) => theme.spacing(6),
        }}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {isSupported === true && (
          <IconLoader
            icon="CheckmarkIcon"
            role="img"
            aria-hidden={false}
            aria-label={t(
              "MarketplaceProductComparison.productComparisonBenefits.indicatorIconLabel.checked"
            )}
            color="primary"
            fontSize="small"
          />
        )}

        {isSupported === false && (
          <IconLoader
            icon="CrossIcon"
            role="img"
            aria-hidden={false}
            aria-label={t(
              "MarketplaceProductComparison.productComparisonBenefits.indicatorIconLabel.unchecked"
            )}
            color="disabled"
            fontSize="small"
          />
        )}
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          border: (theme) => `2px dashed ${theme.palette.primary.main}`,
          borderRadius: 2,
          minHeight: (theme) => theme.spacing(6),
        }}
      ></Box>
    </>
  );
}
