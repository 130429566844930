import { Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import DefaultAccordion from "../DefaultAccordion";
import { ProductBenefit } from "../../services/core-api-adapter";
import IconLoader from "../IconLoader";

interface ProductBenefitAccordionProps {
  benefit: ProductBenefit;
  icon: any;
  subtitle?: string;
}

export default function ProductBenefitAccordion({
  benefit,
  subtitle,
  icon,
}: ProductBenefitAccordionProps) {
  const { t } = useTranslation();

  return (
    <DefaultAccordion
      key={benefit.name}
      title={benefit.name}
      subtitle={subtitle}
      titleVariant="h4"
      titleFontWeight={600}
      icon={icon}
    >
      <Stack spacing={2}>
        {(benefit.description?.split("\r\n") || []).map((item: any) => {
          return (
            <Typography key={item} variant="body2">
              {item}
            </Typography>
          );
        })}

        {benefit.supportingDocumentUri && (
          <Button
            size="medium"
            component="a"
            href={benefit.supportingDocumentUri}
            target="_blank"
            startIcon={<IconLoader icon="DownloadIcon" />}
          >
            {t("common.downloadPDFButton")}
          </Button>
        )}

        {benefit.supportingInformationUri && (
          <Button
            size="medium"
            component="a"
            href={benefit.supportingInformationUri}
            target="_blank"
            startIcon={<IconLoader icon="EyeOpenIcon" />}
          >
            {t("common.viewListButton")}
          </Button>
        )}
      </Stack>
    </DefaultAccordion>
  );
}
