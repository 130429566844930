import { Box, Button, Slide, Stack, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import useGetProductList from "../../hooks/useGetProductList";
import LoadingSpinner from "../LoadingSpinner";
import MarketplaceProductCard from "../MarketplaceProductCard";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import MaxWidthContainer from "../MaxWidthContainer";
import { shadows } from "../../theme";
import {
  Product,
  findMainMemberProductPremium,
  sortProducts,
  isProductPremiumFree,
} from "../../services/core-api-adapter";
import useGetMemberships from "../../hooks/useGetMemberships";
import { trackEvent } from "../../services/analytics-adapter";

interface MarketplaceProductCardForComparisonProps {
  product: Product;
}

function MarketplaceProductCardForComparison({
  product,
}: MarketplaceProductCardForComparisonProps) {
  const { t } = useTranslation();
  const mainMemberProductPremium = findMainMemberProductPremium(product);

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        borderRadius: 3,
        border: (theme) => `2px solid ${theme.palette.statusGreen.main}`,
        px: 1,
        py: 1,
      }}
    >
      <Box flex={6}>
        {isProductPremiumFree(mainMemberProductPremium) ? (
          <Typography component="span" color="statusGreen" fontWeight="600">
            {t("MarketplaceProductCard.noPriceLabelText")}
          </Typography>
        ) : (
          <Trans
            i18nKey="common.pricePerMonth"
            values={{
              price: mainMemberProductPremium?.premiumFormatted,
              interval: t(
                `PaymentInterval.${mainMemberProductPremium?.interval as any}.label` as any
              ),
            }}
          >
            <Typography
              component="span"
              color="statusGreen"
              fontWeight="600"
            ></Typography>
          </Trans>
        )}
      </Box>
      <Typography flex={6} fontWeight="600">
        {product?.name}
      </Typography>
    </Stack>
  );
}

interface MarketplaceProductListProps {
  productIDs: string;
}

export default function MarketplaceProductList({
  productIDs,
}: MarketplaceProductListProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [productSelectionSummaryHeight, setProductSelectionSummaryHeight] =
    useState(0);
  const productSelectionSummaryReference = useRef<HTMLElement>(null);

  const { memberships, membershipsFetchError, isMembershipsLoading } =
    useGetMemberships();

  const { products, productsFetchError, isProductsLoading } =
    useGetProductList();

  const [sortedProducts, setSortedProducts] = useState<Product[]>([]);

  const [productComparisonList, setProductComparisonList] = useState<string[]>(
    []
  );
  const MAXIMUM_NUMBER_OF_PRODUCTS_TO_COMPARE = 2;

  function isActivePlan(product: any): boolean {
    const foundActiveMembership = memberships.filter((membership) => {
      return membership.productDetails.code === product.code;
    });

    if (foundActiveMembership.length) {
      return true;
    }

    return false;
  }

  function sortAndReorderProducts(products: Product[]): Product[] {
    const sortedProducts = sortProducts(products);

    sortedProducts.forEach((product: Product, index: number) => {
      if (isActivePlan(product)) {
        const activePlan = product;
        products.splice(index, 1);
        products.unshift(activePlan);
      }
    });

    return sortedProducts;
  }

  useEffect(() => {
    const sortedAndReorderedProducts = sortAndReorderProducts(products);
    setSortedProducts(sortedAndReorderedProducts);

    const productIdList = productIDs ? productIDs.split(",") : [];
    if (products) {
      setProductComparisonList(productIdList);
    }
  }, [products, memberships, productIDs]);

  useEffect(() => {
    setProductSelectionSummaryHeight(
      productSelectionSummaryReference?.current?.clientHeight || 0
    );
  }, [productComparisonList]);

  function onToggleAddToComparison(productID: string) {
    let temporaryProductComparisonList = productComparisonList.slice();
    const existingProductInComparisonListIndex =
      productComparisonList.indexOf(productID);

    if (existingProductInComparisonListIndex > -1) {
      temporaryProductComparisonList.splice(
        existingProductInComparisonListIndex,
        1
      );
    } else {
      if (
        temporaryProductComparisonList.length ===
        MAXIMUM_NUMBER_OF_PRODUCTS_TO_COMPARE
      ) {
        temporaryProductComparisonList = temporaryProductComparisonList.slice(
          0,
          MAXIMUM_NUMBER_OF_PRODUCTS_TO_COMPARE - 1
        );
      }
      temporaryProductComparisonList.push(productID);
    }

    setProductComparisonList(temporaryProductComparisonList);
  }

  function clearProductComparisonSelection() {
    setProductComparisonList([]);
  }

  function onCompareSelectedPlansButtonClick() {
    const selectedProducts = productComparisonList.map((productID) => {
      const product = products.find((product) => product.id === productID);

      return {
        id: product?.id,
        name: product?.name,
      };
    });

    const comparisonProduct1 = selectedProducts[0];
    const comparisonProduct2 = selectedProducts[1];

    trackEvent({
      event: "action.marketplaceProductsCompared",
      source: "marketplace",
      "comparisonProduct1.productID": comparisonProduct1?.id,
      "comparisonProduct1.productName": comparisonProduct1?.name,
      "comparisonProduct2.productID": comparisonProduct2?.id,
      "comparisonProduct2.productName": comparisonProduct2?.name,
    });

    navigate(
      `/home/marketplace/product-comparison/${productComparisonList.join(",")}`
    );
  }

  if (isProductsLoading || isMembershipsLoading) {
    return (
      <Stack alignItems="center">
        <LoadingSpinner />
      </Stack>
    );
  }

  if (!isProductsLoading && !isMembershipsLoading && productsFetchError) {
    return (
      <Typography textAlign="center">
        {t("common.somethingWentWrong")}
      </Typography>
    );
  }

  if (
    !isProductsLoading &&
    !productsFetchError &&
    !isMembershipsLoading &&
    !membershipsFetchError &&
    products.length === 0
  ) {
    return (
      <Typography textAlign="center">
        {t("MarketplaceProductList.empty")}
      </Typography>
    );
  }

  return (
    <Stack
      spacing={2}
      pb={`${productSelectionSummaryHeight}px`}
      role="list"
      aria-label={t("MarketplaceProductList.title")}
    >
      {sortedProducts.map((product) => {
        return (
          <MarketplaceProductCard
            key={product.name}
            role="listitem"
            product={product}
            isActivePlan={isActivePlan(product)}
            isSelectedForComparison={
              productComparisonList.indexOf(product.id || "") > -1
            }
            disableComparePlan={
              productComparisonList.length >= 2 ? true : false
            }
            toggleAddToComparison={onToggleAddToComparison}
          />
        );
      })}

      <Slide
        ref={productSelectionSummaryReference}
        in={productComparisonList.length > 0}
        direction="up"
      >
        <Box
          sx={{
            bottom: 0,
            left: 0,
            position: "fixed",
            width: "100%",
          }}
        >
          <MaxWidthContainer>
            <Stack
              spacing={1}
              bgcolor="background.paper"
              sx={{
                borderRadius: 3,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                boxShadow: shadows.medium,
                p: 2,
              }}
            >
              <Stack spacing={1}>
                {productComparisonList.map((productID) => {
                  const product =
                    products.find((product) => product.id === productID) ||
                    null;

                  if (product) {
                    return (
                      <MarketplaceProductCardForComparison
                        key={productID}
                        product={product}
                      />
                    );
                  }
                })}
              </Stack>

              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={{ xs: 1, sm: 2 }}
              >
                <Button
                  variant="outlined"
                  onClick={clearProductComparisonSelection}
                >
                  {t("common.cancelButton")}
                </Button>

                {productComparisonList.length ===
                MAXIMUM_NUMBER_OF_PRODUCTS_TO_COMPARE ? (
                  <Button
                    sx={{ flexGrow: 1 }}
                    onClick={onCompareSelectedPlansButtonClick}
                  >
                    {t("MarketplaceProductList.compareSelectedPlansButton")}
                  </Button>
                ) : (
                  <Button sx={{ flexGrow: 1 }} disabled>
                    {t("MarketplaceProductList.tooFewForComparisonButton", {
                      count: MAXIMUM_NUMBER_OF_PRODUCTS_TO_COMPARE,
                    })}
                  </Button>
                )}
              </Stack>
            </Stack>
          </MaxWidthContainer>
        </Box>
      </Slide>
    </Stack>
  );
}
