import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Grid,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import {
  Member,
  ProductBenefit,
  Product,
  findMainMemberInMembership,
} from "../../services/core-api-adapter";
import useGetProductList from "../../hooks/useGetProductList";
import MarketplaceProductComparisonCard from "./components/MarketplaceProductComparisonCard";
import ProductBenefitSupportIndicator from "./components/ProductBenefitSupportIndicator";
import LoadingSpinner from "../LoadingSpinner";
import useGetMemberships from "../../hooks/useGetMemberships";
import { useNavigate } from "react-router";
import CircledBackButton from "../CircledBackButton";
import theme from "../../theme";
import ProductComparisonCardPlaceHolder from "./components/ProductComparisonCardPlaceholders";
import { useGlobalStore } from "../../store";

const MAXIMUM_NUMBER_OF_PRODUCTS_TO_COMPARE = 2;

enum MarketplaceComparisonViewType {
  SUMMARY_VIEW = "Summary view",
  DETAILED_VIEW = "Detailed view",
}

function getProductBenefitNamesForProduct(product: Product): ProductBenefit[] {
  const productBenefits: ProductBenefit[] = [];

  product?.benefitCategory?.forEach((benefitCategory) => {
    benefitCategory.benefits.forEach((benefit) => {
      productBenefits.push(benefit);
    });
  });

  return productBenefits;
}

function getUniqueProductBenefitsForProducts(
  products: Product[]
): ProductBenefit[] {
  const productBenefitsNotUnique: ProductBenefit[] = [];

  products.forEach((product) => {
    getProductBenefitNamesForProduct(product).forEach((productBenefit) => {
      productBenefitsNotUnique.push(productBenefit);
    });
  });

  const uniqueBenefits: ProductBenefit[] = productBenefitsNotUnique.filter(
    (value, index, array) => {
      return array.findIndex((i) => i.name === value.name) === index;
    }
  );

  return uniqueBenefits;
}

interface MarketplaceProductComparisonProps {
  productIDs: string;
}

export default function MarketplaceProductComparison({
  productIDs,
}: MarketplaceProductComparisonProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [viewType, setViewType] = useState(
    MarketplaceComparisonViewType.SUMMARY_VIEW
  );
  const { state } = useGlobalStore();
  const { products, productsFetchError, isProductsLoading } =
    useGetProductList();
  const { firstMembership, memberships } = useGetMemberships();
  const [member, setMember] = useState<Member | null>(null);
  const [productList, setProductList] = useState<Product[]>([]);
  const [
    combinedProductBenefitsForSelectedProducts,
    setCombinedProductBenefitsForSelectedProducts,
  ] = useState<ProductBenefit[]>([]);

  const productIdList = (productIDs || "").split(",");
  const placeHolderCount =
    MAXIMUM_NUMBER_OF_PRODUCTS_TO_COMPARE - productList.length;

  function isActivePlan(product: any): boolean {
    const foundActiveMembership = memberships.filter((membership) => {
      return membership.productDetails.code === product.code;
    });

    if (foundActiveMembership.length) {
      return true;
    }
    return false;
  }

  const isDetailedView =
    viewType === MarketplaceComparisonViewType.DETAILED_VIEW;

  function onViewTypeToggleChange(value: MarketplaceComparisonViewType) {
    if (value !== null) {
      setViewType(value);
    }
  }

  function onBackButtonClick() {
    if (productList.length) {
      navigateToMarketplaceWithSelectedProducts();
    } else {
      navigate("/home/marketplace");
    }
  }

  function navigateToMarketplaceWithSelectedProducts() {
    const redirectProductIDs = productList.map((product) => product.id);
    navigate(`/home/marketplace/${redirectProductIDs.join(",")}`);
  }

  function onRemovePlanButtonClick(productToRemove: any) {
    const newProductList = productList.filter((product) => {
      return product.id !== productToRemove.id;
    });
    setProductList(newProductList);

    const redirectProductIDs = newProductList.map((product) => product.id);
    window.history.replaceState(
      null,
      "",
      `/home/marketplace/product-comparison/${redirectProductIDs.join(",")}`
    );
  }

  useEffect(() => {
    if (products) {
      const selectedProducts = products.filter((product) => {
        return productIdList.indexOf(product?.id || "") > -1;
      });

      setProductList(selectedProducts);
      setCombinedProductBenefitsForSelectedProducts(
        getUniqueProductBenefitsForProducts(selectedProducts)
      );
    }

    setMember(
      findMainMemberInMembership(firstMembership, state.currentUser.memberId)
    );
  }, [products, productIDs]);

  return (
    <>
      <Box sx={{ p: 2 }}>
        <CircledBackButton
          onClick={onBackButtonClick}
          label={t("MarketplaceProductComparison.backButtonLabel")}
          showLabel={true}
        />
      </Box>

      <Box sx={{ p: 2 }}>
        <ToggleButtonGroup
          color="neutral"
          value={viewType}
          onChange={(_event, value) => onViewTypeToggleChange(value)}
          fullWidth
          exclusive
        >
          <ToggleButton value={MarketplaceComparisonViewType.SUMMARY_VIEW}>
            {t("MarketplaceProductComparison.viewType.SummaryView.toggleLabel")}
          </ToggleButton>
          <ToggleButton value={MarketplaceComparisonViewType.DETAILED_VIEW}>
            {t(
              "MarketplaceProductComparison.viewType.DetailedView.toggleLabel"
            )}
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <Box sx={{ p: 2 }}>
        {isProductsLoading && (
          <Stack alignItems="center" p={2}>
            <LoadingSpinner />
          </Stack>
        )}

        {!isProductsLoading && productsFetchError && (
          <Typography textAlign="center">
            {t("MarketplaceProductComparison.errorLoadingProducts")}
          </Typography>
        )}

        {!isProductsLoading && !productsFetchError && (
          <>
            <Typography variant="h3">
              {t("MarketplaceProductComparison.title")}
            </Typography>
            <Grid
              container
              spacing={2}
              my={2}
              role="list"
              aria-label={t("MarketplaceProductComparison.list.title")}
              sx={{
                position: "sticky",
                top: 0,
              }}
            >
              {!isDetailedView && <Grid item xs={4}></Grid>}

              {productList.map((product) => {
                return (
                  <Grid
                    key={product.id}
                    item
                    xs={isDetailedView ? 6 : 4}
                    role="listitem"
                    title={product?.name}
                  >
                    <MarketplaceProductComparisonCard
                      isActivePlan={isActivePlan(product)}
                      isDetailedView={isDetailedView}
                      onClose={() => onRemovePlanButtonClick(product)}
                      product={product}
                      membershipIdentifier={member?.membershipDetails.id}
                    />
                  </Grid>
                );
              })}

              {placeHolderCount > 0 && (
                <ProductComparisonCardPlaceHolder
                  count={placeHolderCount}
                  isDetailedView={isDetailedView}
                  onAddNewPlanButtonClick={
                    navigateToMarketplaceWithSelectedProducts
                  }
                />
              )}
            </Grid>

            {combinedProductBenefitsForSelectedProducts.map(
              (productBenefit, productBenefitNameIndex) => {
                return (
                  <Box
                    role="list"
                    key={String(productBenefit.name + productBenefitNameIndex)}
                    aria-label={t(
                      "MarketplaceProductComparison.productComparisonBenefits.list.ariaLabel"
                    )}
                  >
                    <Grid
                      role="listitem"
                      aria-label={productBenefit.name}
                      container
                      spacing={2}
                      mb={2}
                      alignItems="center"
                    >
                      {isDetailedView ? (
                        <Grid xs={10} px={2} mt={3} spacing={2}>
                          <Typography variant="h4" mb={1}>
                            {productBenefit.name}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            color={theme.palette.neutral[500]}
                          >
                            {productBenefit.oneLiner}
                          </Typography>
                        </Grid>
                      ) : (
                        <Grid item xs={4} display="flex" alignItems="center">
                          {productBenefit.name}
                        </Grid>
                      )}

                      {productList.map((product, productIndex) => {
                        const productBenefits: ProductBenefit[] =
                          getProductBenefitNamesForProduct(product).map(
                            (productBenefitName) => {
                              return productBenefitName;
                            }
                          );

                        return (
                          <Grid
                            item
                            key={productBenefit + String(productIndex)}
                            xs={isDetailedView ? 6 : 4}
                            textAlign="center"
                          >
                            <ProductBenefitSupportIndicator
                              productBenefitName={productBenefit.name}
                              productName={product.name}
                              isSupported={
                                productBenefits.findIndex(
                                  (item) => item.name === productBenefit.name
                                ) > -1
                                  ? true
                                  : false
                              }
                            />
                          </Grid>
                        );
                      })}

                      {placeHolderCount > 0 &&
                        [...Array(placeHolderCount)].map((item, index) => {
                          const ariaLabel = t(
                            "MarketplaceProductComparison.productComparisonBenefits.placeholder.ariaLabel"
                          );
                          return (
                            <Grid
                              item
                              key={ariaLabel + String(index)}
                              xs={isDetailedView ? 6 : 4}
                              textAlign="center"
                            >
                              <Stack component="article" aria-label={ariaLabel}>
                                <ProductBenefitSupportIndicator />
                              </Stack>
                            </Grid>
                          );
                        })}
                    </Grid>
                  </Box>
                );
              }
            )}
          </>
        )}
      </Box>
    </>
  );
}
