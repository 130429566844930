import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Box, Button, Stack, Typography } from "@mui/material";
import {
  Product,
  submitProductSelectionCallMeBack,
} from "../../services/core-api-adapter";
import LoadingSpinner from "../LoadingSpinner";
import IconLoader from "../IconLoader";
import { useNavigate } from "react-router";
import MarketplaceProductChoosePlanSelfServiceFormSuccess from "../MarketplaceProductChoosePlanSelfServiceFormSuccess";
import MarketplaceProductChoosePlanSelfServiceFormFailure from "../MarketplaceProductChoosePlanSelfServiceFormFailure";
import { trackEvent } from "../../services/analytics-adapter";
import {
  FeatureToggleIdentifier,
  isFeatureEnabled,
} from "../../services/feature-toggle-adapter";

interface MarketplaceProductChoosePlanSelfServiceFormProps {
  product: Product;
  onClose?: () => void;
}

enum FormState {
  COLLECTING = "COLLECTING_FORM_DATA",
  SUBMITTING = "SUBMITTING_FORM_DATA",
  SUCCEEDED = "SUBMISSION_SUCCEEDED",
  FAILED = "SUBMISSION_FAILED",
}

export default function MarketplaceProductChoosePlanSelfServiceForm({
  product,
  onClose,
}: MarketplaceProductChoosePlanSelfServiceFormProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [state, setState] = useState(FormState.COLLECTING);

  function onCallMeBackButtonClick() {
    trackEvent({
      event: "action.marketplaceCheckoutFinished",
      productId: product?.id,
      productName: product?.name,
      selectedOption: "callMeBack",
    });
    const controller = new AbortController();

    setState(FormState.SUBMITTING);

    submitProductSelectionCallMeBack({
      signal: controller.signal,
    })
      .then(() => {
        setState(FormState.SUCCEEDED);
      })
      .catch(() => {
        if (controller.signal.aborted === false) {
          setState(FormState.FAILED);
        }
      });
  }

  function onSignUpMyselfButtonClick() {
    trackEvent({
      event: "action.marketplaceCheckoutFinished",
      productId: product?.id,
      productName: product?.name,
      selectedOption: "signUpMySelf",
    });
    return navigate(
      `/home/marketplace/product/${product.id}/confirm-self-service-sign-up`
    );
  }

  return (
    <>
      {state === FormState.COLLECTING && (
        <Stack component="article" spacing={2}>
          <Box textAlign="center">
            <IconLoader
              icon="TrolleyWithPlusIcon"
              color="primary"
              sx={{
                fontSize: (theme) => theme.spacing(10),
              }}
            />
          </Box>

          <Typography textAlign="center" variant="h3" fontWeight="600">
            {t("common.chooseThisPlan")}
          </Typography>

          {(
            t<any, any, string[]>(
              "MarketplaceProductChoosePlanSelfServiceForm.body",
              {
                productName: product.name,
                returnObjects: true,
              }
            ) as []
          ).map((text: string) => (
            <Typography key={text} textAlign="center">
              <Trans i18nKey={text as any} />
            </Typography>
          ))}

          <Stack component="footer" spacing={1} direction="row">
            {isFeatureEnabled(
              FeatureToggleIdentifier.ENABLE_MARKETPLACE_CALL_BACK
            ) === true && (
              <Button
                variant="outlined"
                size="small"
                fullWidth
                onClick={onCallMeBackButtonClick}
              >
                {t("common.callMeBack")}
              </Button>
            )}

            <Button size="small" fullWidth onClick={onSignUpMyselfButtonClick}>
              {t("common.signUpMyself")}
            </Button>
          </Stack>
        </Stack>
      )}

      {state === FormState.SUBMITTING && (
        <Box textAlign="center">
          <LoadingSpinner />
        </Box>
      )}

      {state === FormState.SUCCEEDED && (
        <MarketplaceProductChoosePlanSelfServiceFormSuccess onClose={onClose} />
      )}

      {state === FormState.FAILED && (
        <MarketplaceProductChoosePlanSelfServiceFormFailure
          onTryAgain={() => {
            setState(FormState.COLLECTING);
          }}
        />
      )}
    </>
  );
}
